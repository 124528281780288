module.exports = {
    seo: {
        title : "מאמר - ייצור  המוני - Jenko",
        description:  ""
      },
  intro: {
      title: "ייצור  המוני"
  },
  articleHeader: {
      title: "מפיתוח לייצור",
      desc: "בשרשרת הפעולות שמובילה מתולדה של רעיון מצוין למוצר שעומד על המדפים בחנויות, יש מספר שלבים קבועים. בהתחלה מפתחים את הרעיון ובודקים את טיבו מבחינה הנדסית, שיווקית וכמובן פיננסית. לאחר מכן מבצעים אפיון ובדיקת היתכנות. בשלב הבא הרעיון קורם עור וגידים על ידי הפיכה לאבטיפוס ואם בוחרים לעשות כן, גם רושמים עליו פטנט. בשלב הבא בונים אסטרטגיה שיווקית, תכנית עסקית וכל מה שצריך כדי לגייס משקיעים. ברגע שיש כסף, אפשר להתחיל לרוץ. מבצעים הכנה לייצור המוני\u00A0ויוצאים\u00A0לדרך."
  },
  secP: {
      title: "כיצד נעשה בפועל?",
      p:  [    
            " ייצור יחידני וייצור המוני. ייצור יחידני מתייחס בדרך כלל לפריטים ייחודיים וחד פעמיים כמו למשל עבודת עץ בהתאמה אישית שמזמינים מנגר או תכשיט ייחודי ממעצב. הייצור ההמוני לעומת זאת, כשמו כן הוא. שיטה שמתאימה לייצור פריטים זהים שאנו רוצים לייצר בכמויות גדולות מאד. לרשותנו עומדות טכנולוגיות רבות שתסייע לנו במלאכת הייצור. כמה דוגמאות לשיטות פופולריות לייצור\u00A0המוני:",
           "את שוק הייצור ניתן לחלק בגדול לשניים:"
      ],
      addP: ["כרסום – שיטת ייצור שפועלת באמצעות טכניקה של עיבוד שבבי.", "חריטה – שיטת ייצור נוספת של עיבוד שבבי המתבצעת באמצעות חריטה ידנית או ממחושבת (CNC). ", " קיימות שיטות נוספות כמו חיתוך לייזר, ניפוח, כיפוף, כבישה, יציקה, הזרקת פלסטיק לתבניות עליה יפורט\u00A0בהמשך."]
  },
  thrdP: {
      title: "עלויות",
      p: [
          "כשמדברים על עלויות ייצור המוני צריך קודם כל להבין את הדרך לשם. מפעלים שמספקים שירות של ייצור המוני זמינים כיום ברחבי העולם. ניתן לייצר במדינת ישראל, באירופה, בארצות הברית וגם במזרח. סין מושכת את עיקר תשומת לב היצרנים וזאת בזכות המגוון הרחב שהיא מציעה בתעריפים וברמות האיכות. אז איך בעצם בוחרים? את תהליך בחירת המפעל בפרט ואת תהליך הייצור ההמוני בכלל נהוג לעשות בליווי חברה\u00A0שמתמחה\u00A0בכך.",
          "השלב הראשון שתבצע החברה יהיה סקר שוק יסודי שמטרתו בחירה של יצרן מדויק ככל האפשר עבור הפרויקט. בשלב הבא תעביר החברה את המוצר יחד עם דרישות הייצור לצוות המפעל ותהיה בקשר צמוד עמם כדי לוודא שהמענה שמתקבל הנו אופטימלי. בשלב הבא מקימים את פסי הייצור, מתחילים את תהליך הייצור ומשם כל מה שנשאר הוא אריזה, הרכבה ומשלוח לארץ ולחוץ לארץ  אם יש צורך בכך. למעשה המרכיב שבאמצעותו ייצור המוני מתאפשר. לתוך התבניות יוצקים את חומר הגלם הנבחר והן מאפשרות לייצר את המוצר בכמויות מסחריות. יצירת התבניות, אם כן, הנו שלב קריטי בתהליך הייצור. כדאי מאד להשקיע בשלב זה כי תבניות איכותיות הן ערובה למוצר איכותי. חומרי הגלם אותם יוצקים לתוך התבניות הם בדרך כלל החלק הזול יותר. המספרים עצמם תלויים כמובן בסוג המוצר ושיטת הייצור ולאלו מתוודעים בתחילת תהליך הפיכת הרעיון למוצר (כבר בשלב בדיקת\u00A0ההיתכנות)."
      ]
  },
  frthP: {
      title: "ייצור בסין",
      p: "בשלושים השנים האחרונות התברגה סין כיצרנית מספר אחת בעולם וזאת לאור התעריפים חסרי התחרות שמציעים המפעלים שם. במרוצת השנים חלק ממפעלים אלו החלו לייקר את מחיריהם מה שהחזיר את האטרקטיביות לשחקנים אמריקאים ואירופאיים. סין מציעה מנעד רחב של אפשרויות איכות ומנעד רחב של מחירים בהתאם. אם בוחרים בעבודה מול סין חשוב מאד להיעזר בליווי של גורם המנוסה בעבודה מול הסינים. זה חשוב הן בכדי לאתר יצרן מתאים למשימה שהוא גם איכותי וגם לא יקר והן לצורך ההתנהלות השוטפת מול הסינים שמצריכה גם היא ידע וניסיון. כמו כן, גורם מנוסה יידע לבצע בדיקות עמידה בתקנים ובסטנדרטים הנדרשים. חשוב מאד להיות בעמדה של ניהול בקרה ומעקב מתמידים לאורך כל התהליך על מנת להבטיח ייצור איכותי תוך מינימום תקלות. דוגמאות למוצרים יומיומיים שמיוצרים בסין: לפטופים, טלפונים ניידים, ביגוד והנעלה, מוצרי חשמל, ציוד מטבח\u00A0ועוד."
  },
  ffthP: {
      title: "ייצור באמצעות הזרקת\u00A0פלסטיק",
      p: [
          "פלסטיק הוא אחד מחומרי הייצור הפופולריים ביותר כיום. בדרך כלל כאשר אומרים 'פלסטיק' בלשון היומיום מתכוונים למוצרים מפלסטיק קשיח אך קשת סוגי הפלסטיק היא רחבה. לצורך ההמחשה: משאף לאסתמטיים, שקית\u00A0סופר, CD, מיכל חלב של שני ליטרים, אלו כולם מוצרי פלסטיק. פלסטיק הוא למעשה שם גג האוגד תחתיו קשת רחבה של חומרים סינתטיים וחצי סינתטיים שנוחים לייצור בזכות היכולת להעניק להם צורה בקלות על ידי חימום והפעלת לחץ.",
          "תהליך ייצור בהזרקת פלסטיק זהה לתהליך שכבר פירטנו בפסקאות הקודמות. ראשית, מייצרים תבניות המותאמות לייצור המוצר הנבחר. לאחר מכן בוחרים את חומר הגלם שממנו מייצרים את המוצר. מוצרי פלסטיק המיוצרים בהזרקת פלסטיק הם למשל: מוצרי חשמל, ציוד רפואי, אביזרי נוי ורהיטים,\u00A0צעצועים\u00A0ועוד. "
      ]
  },
  dir: 'rtl'
};